@font-face {
  font-family: 'PT Root UI';
  src: url('/fonts/PT Root UI/PT Root UI_Light.eot');
  src: url('/fonts/PT Root UI/PT Root UI_Light.eot?#iefix') format('embedded-opentype'),
       url('/fonts/PT Root UI/PT Root UI_Light.woff2') format('woff2'),
       url('/fonts/PT Root UI/PT Root UI_Light.woff') format('woff'),
       url('/fonts/PT Root UI/PT Root UI_Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('/fonts/PT Root UI/PT Root UI_Regular.eot');
  src: url('/fonts/PT Root UI/PT Root UI_Regular.eot?#iefix') format('embedded-opentype'),
       url('/fonts/PT Root UI/PT Root UI_Regular.woff2') format('woff2'),
       url('/fonts/PT Root UI/PT Root UI_Regular.woff') format('woff'),
       url('/fonts/PT Root UI/PT Root UI_Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('/fonts/PT Root UI/PT Root UI_Medium.eot');
  src: url('/fonts/PT Root UI/PT Root UI_Medium.eot?#iefix') format('embedded-opentype'),
       url('/fonts/PT Root UI/PT Root UI_Medium.woff2') format('woff2'),
       url('/fonts/PT Root UI/PT Root UI_Medium.woff') format('woff'),
       url('/fonts/PT Root UI/PT Root UI_Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PT Root UI';
  src: url('/fonts/PT Root UI/PT Root UI_Bold.eot');
  src: url('/fonts/PT Root UI/PT Root UI_Bold.eot?#iefix') format('embedded-opentype'),
       url('/fonts/PT Root UI/PT Root UI_Bold.woff2') format('woff2'),
       url('/fonts/PT Root UI/PT Root UI_Bold.woff') format('woff'),
       url('/fonts/PT Root UI/PT Root UI_Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('/fonts/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Quicksand';
  src: url('/fonts/Quicksand-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Mono', monospace;
  src: url('/fonts/RobotoMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  animation-name: flash;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, h1, h2, h3, h4, h5, h6,
button, input, textarea, ul, li,
a, div, p, span, th, tr, td {
  font-family: "PT Root UI" !important;
}

a, button {
  text-decoration: none;
  transition: all .25s;
}

//transcript text
.transcript-content {
  display: table;
  font-weight: 400;
  margin-bottom: 20px;

  .transcript-row {
    display: table-row;

    & > span {
      display: table-cell;
      padding-bottom: 0.5rem;

      &:first-child {
        padding-right: 1rem;
      }

      & > b {
        font-weight: 400 !important;
      }

      .tts {
        font-family: "Roboto Mono", monospace !important;
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #8293A6;
        cursor: pointer;

        &:hover {
          color: #0F3580;
        }
      }
    }

    &.analyst-row {
      font-weight: bold;

      & > span > b {
        font-weight: bold !important;
      }
    }
  }
}

//table of contents
.toc-content {
  display: table;

  .toc-row {
    display: table-row;

    & > span {
      display: table-cell;
      padding-bottom: 0.25rem;

      &:first-child {
        padding-right: 0.5rem;
      }

      &.toc-link {
        font-family: "Roboto Mono", monospace !important;
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #8293A6;
        cursor: pointer;

        &:hover {
          color: #0F3580;
        }
      }
    }
  }
}

button:hover .paginationIcon path {
  stroke: #FFFFFF;
}

.gutter.gutter-horizontal {
  cursor: ew-resize;
  background-color: #FFFFFF;
  border-left: 1px solid #EAEEF4;

  &:before {
    content: " ";
    position: fixed;
    display: block;
    top: 50%;
    margin-top: -9px;
    height: 18px;
    width: 6px;
    margin-left: 4px;
    background: #FFFFFF url(/images/icons/gutter.png) no-repeat;
  }
}

//active campaing classes
._form._form_1 {
  display: none;
}

#activeCampaingForm ._form._form_1 {
  display: block;
  font-family: "PT Root UI" !important;
  padding: 20px !important;
  margin: 0 auto !important;
  max-width: 500px;
  width: inherit;
  border-radius: 5px !important;

  ._form-title {
    font-size: 24px !important;
    line-height: 30px !important;
    font-weight: bold !important;
    color: #182341;
  }

  ._html-code p {
    font-size: 16px !important;
    line-height: 22px;
    color: #182341;
    margin-bottom: 0;
  }

  ._form-label {
    color: #8293A6;
    font-weight: 500 !important;
    margin-bottom: 8px !important;
    line-height: 18px;
  }

  ._field-wrapper input {
    border: 1px solid #EAEEF4 !important;
    padding: 0 .75rem !important;
    font-weight: 500;
    height: 40px !important;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    line-height: 18px;
    box-shadow: none;
    border-radius: 5px !important;

    &:hover {
      border: 1px solid #BFCCDC !important;
    }

    &:active, &:focus {
      border: 1px solid #1168A7 !important;
    }
  }

  ._field-wrapper select {
    border: 1px solid #EAEEF4 !important;
    padding: 0 1.125rem 0 .75rem !important;
    font-weight: 500;
    height: 40px !important;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    line-height: 18px;
    box-shadow: none;
    border-radius: 5px !important;

    option {
      color: #182341 !important;

      &:hover {
        background-color: #1168A7 !important;
        color: #FFFFFF !important;
      }
    }

    &:hover {
      border: 1px solid #BFCCDC !important;
    }

    &:active, &:focus {
      border: 1px solid #1168A7 !important;
    }
  }

  ._submit {
    font-family: "PT Root UI" !important;
    color: #FFFFFF !important;
    background-color: #1168A7 !important;
    border: 1px solid #1168A7 !important;
    display: inline-block;
    width: 100%;
    padding: 0 .75rem !important;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    line-height: 18px;
    border-radius: 5px !important;

    &:hover {
      color: #FFFFFF !important;
      background-color: #0A568C !important;
      border: 1px solid #0A568C !important;
    }

    &:active {
      color: #FFFFFF !important;
      background-color: #1C82CC !important;
      border: 1px solid #1C82CC !important;
    }
  }

  ._form-branding {
    display: none !important;
  }
}

#activeCampaingForm ._form._form_4 {
  display: block;
  font-family: "PT Root UI" !important;
  padding: 20px !important;
  margin: 0 auto !important;
  max-width: 365px;
  width: inherit;
  border-radius: 5px !important;

  ._form_element._x11227545,
  ._form_element._x24026506 {
    margin-bottom: 10px;
  }

  ._form-title {
    font-size: 20px !important;
    line-height: 32px !important;
    font-weight: bold !important;
    color: #182341;
    text-align: center;
  }

  ._html-code p {
    font-size: 14px !important;
    line-height: 22px;
    font-weight: 500 !important;
    color: #182341;
    margin-bottom: 0;
    text-align: center;
  }

  ._form-label {
    color: #8293A6;
    font-weight: 500 !important;
    margin-bottom: 8px !important;
    line-height: 18px;
  }

  ._field-wrapper input {
    border: 1px solid #EAEEF4 !important;
    padding: 0 .75rem !important;
    font-weight: 500;
    height: 40px !important;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    line-height: 18px;
    box-shadow: none;
    border-radius: 5px !important;

    &:hover {
      border: 1px solid #BFCCDC !important;
    }

    &:active, &:focus {
      border: 1px solid #1168A7 !important;
    }
  }

  ._field-wrapper select {
    border: 1px solid #EAEEF4 !important;
    padding: 0 1.125rem 0 .75rem !important;
    font-weight: 500;
    height: 40px !important;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    line-height: 18px;
    box-shadow: none;
    border-radius: 5px !important;

    option {
      color: #182341 !important;

      &:hover {
        background-color: #1168A7 !important;
        color: #FFFFFF !important;
      }
    }

    &:hover {
      border: 1px solid #BFCCDC !important;
    }

    &:active, &:focus {
      border: 1px solid #1168A7 !important;
    }
  }

  ._submit {
    font-family: "PT Root UI" !important;
    color: #FFFFFF !important;
    background-color: #88CD00 !important;
    border: 1px solid #88CD00 !important;
    display: inline-block;
    width: 100%;
    padding: 0 .75rem !important;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    line-height: 18px;
    border-radius: 5px !important;
    margin-top: 5px;

    &:hover {
      color: #FFFFFF !important;
      background-color: #539C00 !important;
      border: 1px solid #539C00 !important;
    }

    &:active {
      color: #FFFFFF !important;
      background-color: #BDFF4D !important;
      border: 1px solid #BDFF4D !important;
    }
  }

  ._form-branding {
    display: none !important;
  }
}

.MuiPickersToolbar-toolbar {
  display: block !important;
}

#customized-menu {
  z-index: 1301 !important;
}

.MuiSkeleton-withChildren > * {
    visibility: visible !important;
}

.sentryError-layout {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .sentryError-content {
    position: relative;
    width: 100%;
    max-width: 580px;
    padding: 20px;
    text-align: center;

    .sentryError-img {
      width: 100%;
      margin-bottom: 30px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .sentryError-message {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      color: #182341;
      text-align: center;

      .sentryError-btn {
        display: inline-block;
        background-color: #1168A7;
        color: #FFFFFF;
        padding: 11px 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        text-transform: capitalize;

        &:hover {
          background-color: #0A568C;
        }

        &:active {
          background-color: #1C82CC;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  body {
    height: 100vh;
  }

  div#root {
    height: 100%;
  }
}

@media screen and (min-width: 960px) {
  #activeCampaingForm ._form._form_1 {
    padding: 30px 50px !important;
  }

  #activeCampaingForm ._form._form_4 {
    width: 365px;

    ._form-title {
      font-size: 34px !important;
      line-height: 42px !important;
      max-width: 240px;
      margin: 0 auto;
    }

    ._html-code p {
      font-size: 16px !important;
    }
  }

  .sentryError-layout .sentryError-content .sentryError-img {
    margin-bottom: 60px;
  }

  .sentryError-layout .sentryError-content .sentryError-message {
    font-size: 24px;
    line-height: 30px;
  }
}
